<template>
  <div class="inner_nav_div">
    <!-- <el-tag :key="index" v-for="(tag,index) in menus" :class="tag.check?'is_active':''" closable
      :disable-transitions="false" @close="handleClose(tag)" @click="handleClick(tag)">
      {{tag.name}}
    </el-tag> -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="(item,index) in currentRoute" :key="index" :to="{ path: item.path }">{{item.name}}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
  import {
    mapGetters,
    mapState
  } from 'vuex';
  export default {
    name: 'inner-top-nav',
    data() {
      return {
        dynamicTags: ['标签标签一', '标签二', '标签三'],
        currentRoute: []
      }
    },
    computed: {
      ...mapState({
        menus: state => state.menus.menus,
      }),
      ...mapGetters('menus', {
        menuPath: 'menuPath',
        // total: 'cartTotalPrice'
      })
    },
    mounted() {
      this.currentRoute = [];
      // console.log("当前路由", this.$route);
      for (let i = 0; i < this.$route.matched.length; i++) {
        this.currentRoute.push(this.$route.matched[i])
      }

    },
    methods: {
      handleClick(tag) {
        this.$router.push(tag.path);
      },
      handleClose(tag) {
        // this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
        if (this.menus.length > 1) {

          this.$store.dispatch('menus/subMenus', tag);
          setTimeout(() => {
            console.log('---' + this.menuPath);
            if (this.menuPath != '') {
              this.$router.push(this.menuPath);
            }
          }, 100);

        } else {
          this.$message({
            message: '这是最后一个界面，不能进行删除！',
            type: 'warning'
          });
        }
      },
    }
  }
</script>
<style scoped>
  .inner_nav_div {
    height: 36px;
    width: 100%;
    /* border-bottom: 1px #E6E6E6 solid; */
    display: flex;
    align-items: center;
  }

  .el-tag {
    background-color: #98CCD8;
    color: #ffffff;
  }

  .el-tag+.el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

  .is_active {
    background-color: #20B6DA;
  }
</style>
<style lang="less" scoped>
  ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    font-weight: 700;
    color: #009BFD;
  }
</style>