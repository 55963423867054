<!-- 维修管理-维修工单-修改 -->

<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">基本信息</div>
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_40 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="工单编号：">
                    <el-input v-model="formInline.orderID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <!-- <el-form-item label="公司编号：" class=" ">
                    <el-input v-model="formInline.projectID" placeholder="公司编号" class="width_180" disabled></el-input>
                </el-form-item> -->
                <el-form-item label="设备编号：">
                    <el-input v-model="formInline.deviceID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备名称：" class=" ">
                    <el-input v-model="formInline.deviceName" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备类型：" class=" ">
                    <el-input v-model="formInline.deviceType_name" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备型号：">
                    <el-input v-model="formInline.deviceModel" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="所属部门：" class=" ">
                    <el-input v-model="formInline.deviceDepartment" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备位置：" class=" ">
                    <el-input v-model="formInline.deviceLocation" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
            </el-form>
            <div class="box_head1 width_100">故障信息</div>
            <el-form :inline="true" class="mg_top_40 flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="故障时间：" class=" ">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.failureTime"
                        type="datetime" :clearable="false" placeholder="选择日期时间" class="width_180">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="记录人：" class="">
                    <el-input v-model="formInline.oprPerson" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="记录时间：" class=" ">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.oprTime"
                        type="datetime" :clearable="false" placeholder="选择日期时间" class="width_180">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="故障代码：" class="">
                    <el-input v-model="formInline.failureCode" placeholder="" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="故障部位：">
                    <el-input v-model="formInline.failureDevPart" placeholder="" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="故障等级：">
                    <el-select v-model="formInline.failureLevel" placeholder="" class="width_180">
                        <el-option v-for="(item,index) in levelData" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="故障类型：" class=" ">
                    <el-select v-model="formInline.failureType" placeholder="" class="width_180">
                        <el-option v-for="(item,index) in typeData" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- <el-form-item label="故障描述：" class=" ">
                    <el-input v-model="formInline.failureDesc" placeholder="故障描述" class="width_180"></el-input>
                </el-form-item>

                <el-form-item label="故障原因：" class=" ">
                    <el-input v-model="formInline.failureReason" placeholder="故障原因" class="width_180"></el-input>
                </el-form-item> -->
            </el-form>
            <el-form :model="formInline" label-width="formLabelWidth" style="width: 62%;">
                <el-form-item label="故障描述：">
                    <el-input type="textarea" :rows="3" resize="none" v-model="formInline.failureDesc"
                        placeholder="故障描述"></el-input>
                </el-form-item>
                <!-- </el-form>
            <el-form :model="formInline" label-width="formLabelWidth" style="width: 62%;"> -->
                <el-form-item label="故障原因：">
                    <el-input type="textarea" :rows="3" resize="none" v-model="formInline.failureReason"
                        placeholder="故障原因"></el-input>
                </el-form-item>
            </el-form>
            <div class="box_head1 width_100">处理情况</div>
            <el-form :inline="true" class="mg_top_40 flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="维修组：" class="">
                    <el-input v-model="formInline.execGroup" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="维修人：" class=" ">
                    <el-input v-model="formInline.execPerson" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="维修等级：" class=" ">
                    <el-select v-model="formInline.execLevel" placeholder="" class="width_180">
                        <el-option v-for="(item,index) in execLevel" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="维修类型：" class=" ">
                    <el-select v-model="formInline.execType" placeholder="" class="width_180">
                        <el-option v-for="(item,index) in repairCategory" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否审批：" class=" ">
                    <el-radio-group v-model="formInline.isAuth" class="width_180 text_align_left" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="维修预估花费时间：" class="">
                    <el-input v-model="formInline.execEstimateLen" placeholder="" class="width_180"
                        disabled>
                        <i slot="suffix" style=" color: #606266;" class="fn_size14">分钟</i>
                    </el-input>
                </el-form-item>
                <el-form-item label="工单状态：" class="">
                    <el-input v-model="formInline.orderStatus_name" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>

                <el-form-item label="紧急程度：" class=" ">
                    <el-select v-model="formInline.urgenLevel_name" placeholder="" class="width_180">
                        <el-option v-for="(item,index) in UrgenLevel" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="是否停机：" class="">
                    <el-radio-group v-model="formInline.machineStop" class="width_180 text_align_left">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="停机时长：" class="" v-if="formInline.machineStop">
                    <el-input v-model="formInline.stopLen" placeholder="" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="派单时间：" class="">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date"
                        v-model="formInline.dispatchTime" type="datetime" :clearable="false" placeholder=""
                        class="width_180" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="指定接单时间：" class="">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date"
                        v-model="formInline.assignTakeTime" type="datetime" :clearable="false" placeholder=""
                        class="width_180">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否逾期接单：" class="">
                    <el-radio-group v-model="formInline.isTakeOverdue" class="width_180 text_align_left" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="接单时间：" class="">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.takeTime"
                        type="datetime" :clearable="false" placeholder="" class="width_180" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="维修开始时间：" class="">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.startTime"
                        type="datetime" :clearable="false" placeholder="" class="width_180" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="维修结束时间：" class="">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.endTime"
                        type="datetime" :clearable="false" placeholder="" class="width_180" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否逾期结单：" class="">
                    <el-radio-group v-model="formInline.isEndOverdue" class="width_180 text_align_left" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="指定维修结单时间：" class="">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date"
                        v-model="formInline.assignEndTime" type="datetime" :clearable="false" placeholder=""
                        class="width_180">
                    </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="暂离时间列表：" class="">
                    <el-input v-model="formInline.pasueTimeList" placeholder="暂离时间列表" class="width_180" disabled></el-input>
                </el-form-item> -->
                <el-form-item label="验证时间：" class="">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.verifyTime"
                        type="datetime" :clearable="false" placeholder="" class="width_180" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="验证不通过维修开始时间：" class="">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date"
                        v-model="formInline.execRejectStartTime" type="datetime" :clearable="false"
                        placeholder="" class="width_180" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="验证不通过维修结束时间：" class="">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date"
                        v-model="formInline.execRejectEndTime" type="datetime" :clearable="false"
                        placeholder="" class="width_180" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="维修时长：" class="">
                    <el-input v-model="formInline.execLenM" placeholder="" class="width_180" disabled>
                        <i slot="suffix" style=" color: #606266;" class="fn_size14">分钟</i>
                    </el-input>
                </el-form-item>
                <el-form-item label="维修费用：" class="">
                    <el-input v-model="formInline.execFee" placeholder="维修费用" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="评分等级：" >
                    <el-select v-model="formInline.execGrade" placeholder="" class="width_180">
                        <el-option v-for="(item,index) in gradingArr" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否为经验性记录：" class="">
                    <el-radio-group v-model="formInline.isEmpirical" class="width_180 text_align_left" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否委外：" class="">
                    <el-radio-group v-model="formInline.isoOutEntrust" class="width_180 text_align_left">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <!-- <el-form-item label="验证备注：" class="">
                    <el-input v-model="formInline.verifyRemarks" placeholder="验证备注" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="现场情况：" class="">
                    <el-input v-model="formInline.spotCondition" placeholder="现场情况" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="维修描述：" class="">
                    <el-input v-model="formInline.execDesc" placeholder="维修描述" class="width_180"></el-input>
                </el-form-item> -->
                <!-- <el-form-item label="备注：" class="">
                    <el-input v-model="formInline.remarks" placeholder="工单评分等级" class="width_180"></el-input>
                </el-form-item> -->
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="验证备注：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.verifyRemarks" resize="none"></el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="现场情况：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.spotCondition" resize="none"></el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="维修描述：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.execDesc" resize="none"></el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="备注：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.remarks" resize="none"></el-input>
                </el-form-item>
            </el-form>
            <div class="box_head1 width_100 mg_top_20">更换备件</div>
            <div class="flex flex_column width_100 pdl30 pdr30 height_370">
                <div class="flex align_center mg_top_20">
                    <!-- <el-select v-model="value" placeholder="请选择仓库" class="width_150">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select> -->
                    <el-button type="primary" class="width_140 mg_left_10" style=" background: #03CABE;border: none;"
                        @click="selectSpare">
                        选择备件</el-button>
                    <!-- <el-button type="primary" class="width_140 mg_left_10" style=" background: #03CABE;border: none;">
                        申购备件</el-button> -->
                </div>
                <el-table :data="sparePartList" style="width: 100%" class="mg_top_20" height="100%">
                    <el-table-column type="index" label="序号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartModel" label="规格型号" width="">
                    </el-table-column>
                    <el-table-column prop="unit" label="计量单位" width="">
                    </el-table-column>
                    <el-table-column prop="applyQuan" label="领用数量" width="">
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价" width="">
                    </el-table-column>
                    <el-table-column prop="totalPrice" label="总价" width="">
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" width="">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;" @click="editTable(scope.row)">修改</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="delTable(scope.row.ID)">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex mg_top_20 mg_bottom_30">
                <div>
                    <el-button type="primary" class="width_140" @click="submit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
            <el-dialog title="选择备件" :visible.sync="dialogTableVisibleSpare" top='15vh' width="60%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">新增备件</div> -->
                <!-- pdl30 pdr30  height_370 -->
                 <div width="60%" style="margin-top:10px">
                    
                    <div class="flex align_center mg_left_50 fn_size14">
                        <div class="">备件型号：</div>
                        <el-input v-model="sparePartModel" class="width_150" clearable></el-input>
                        <!-- <el-select v-model="sparePartModel" placeholder="全部" class="width_150" clearable>
                            <el-option v-for="item in sparePartModelArray" :key="item.value" :label="item.description"
                                :value="item.value">
                            </el-option>
                        </el-select> -->
                          <div class="mg_left_20">备件名称：</div>
                        <el-input v-model="sparePartName" class="width_150" clearable></el-input>
                        <el-button type="primary" size="medium" class="mg_left_10" @click="onQueryLimit">查询
                        </el-button>
                    </div>
                </div>
                <div class="flex flex_column ">
                    <div class="flex flex_column mg_top_15 height_444">
                        <el-form :model="formData" ref="formData" class="height_340">
                            <el-table :data="formData.tableData" border stripe style="width: 100%;" height="100%"
                                @selection-change="handleSelectionChange">
                                <el-table-column type="selection" width="55">
                                </el-table-column>
                                <el-table-column type="index" label="序号" width="70">
                                </el-table-column>
                                <el-table-column prop="sparePartID" label="备件编号" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="sparePartName" label="备件名称" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="sparePartModel" label="备件型号" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="unit" label="单位" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="referPrice" label="单价" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="applyQuan" label="领用数量" width="">
                                    <template slot-scope="scope">
                                        <el-form-item :prop="'tableData.' + scope.$index + '.applyQuan'"
                                            style="margin-bottom: 0px;">
                                            <el-input type="number" v-model="scope.row.applyQuan" placeholder=""
                                                @input="changeInput(scope.$index)" oninput="if(value<0){value=0}"></el-input>
                                        </el-form-item>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="totalPrice" label="总价" width="">
                                    <template slot-scope="scope">
                                        <el-form-item :prop="'tableData.' + scope.$index + '.totalPrice'"
                                            style="margin-bottom: 0px;">
                                            <el-input v-model="scope.row.totalPrice" placeholder="" readonly>
                                            </el-input>
                                        </el-form-item>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="Remarks" label="备注" width="">
                                    <template slot-scope="scope">
                                        <el-form-item :prop="'tableData.' + scope.$index + '.Remarks'"
                                            style="margin-bottom: 0px;">
                                            <el-input v-model="scope.row.Remarks" placeholder=""></el-input>
                                        </el-form-item>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form>
                        <el-pagination layout="prev, pager, next" :total="pageQuantitySapre*pageSize"
                            :page-size="pageSize" :current-page.sync="currentPageSpare"
                            @current-change="handleCurrentChangeSpare">
                        </el-pagination>
                        <div class="flex justify_center pdb_10">
                            <el-button @click="dialogTableVisibleSpare = false">取 消</el-button>
                            <el-button type="primary" @click="confirmChoices">确 定</el-button>
                        </div>
                    </div>

                </div>
            </el-dialog>
            <el-dialog title="编辑备件" :visible.sync="dialogTableVisibleEditSpare" top='10vh' width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">编辑备件</div> -->
                <!-- pdl30 pdr30  height_370 -->
                <div class="flex flex_column ">
                    <div class="flex justify_center mg_top_20">
                        <el-form :model="formSpare">
                            <el-form-item label="备件编号：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.sparePartID" autocomplete="off" class="" :readonly="true">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="备件名称：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.sparePartName" autocomplete="off" class=""
                                    :readonly="true"></el-input>
                            </el-form-item>
                            <el-form-item label="规格型号：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.sparePartModel" autocomplete="off" class=""
                                    :readonly="true"></el-input>
                            </el-form-item>
                            <el-form-item label="计量单位：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.unit" autocomplete="off" class="" :readonly="true">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="单价：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.unitPrice" type="number" autocomplete="off" class=""
                                    :readonly="true">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="领用数量：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.applyQuan" type="number" autocomplete="off" class=""
                                    @input="changeInputEdit">
                                </el-input>
                            </el-form-item>

                            <el-form-item label="总价：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.totalPrice" type="number" autocomplete="off" class=""
                                    :readonly="true">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="备注：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.remarks" autocomplete="off" class=""></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="flex justify_center pdb_20 ">
                        <el-button @click="dialogTableVisibleEditSpare = false">取 消</el-button>
                        <el-button type="primary" @click="confirmChoicesEdit">确 定</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>



    <!-- </div> -->
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        repairOrderQuery,
        repairOrderDelete,
        repairOrderStatusQuery,
        repairOrderUpdate,
        repairOrderMultiAuthQueryLimit,
        repairUrgenLevelQuery,
        repairOrderExecLevelQuery,
        repairTypeQuery,
        faultTypeQuery,
        faultLevelQuery,
        repairOrderExecGradeQuery,

        repairOrderSparePartQuery,
        repairOrderSparePartDelete,
        repairOrderSparePartUpdate,
        repairOrderSparePartInsert,
        sparePartStockQueryLimit,
          sparePartTypeQuery

    } from "@/api/api_deviceManagement";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                 sparePartName: '',
                 sparePartModel:'',
                sparePartModel:'',
                value1: '',
                formInline: {
                    user: '',
                    region: ''
                },
                formLabelWidth: '110px',
                ID: '',
                repairCategory: [],
                UrgenLevel: [],
                levelData: [],
                gradingArr: [],
                execLevel: [],
                typeData: [],
                dialogTableVisibleSpare: false,
                dialogTableVisibleEditSpare: false,
                sparePartList: [],
                formData: {
                    tableData: []
                },
                formSpare: {},
                currentPageSpare: 1,
                pageQuantitySapre: 0,
                currentPage: 1,
                pageSize: 5,
                pageQuantity: 0,

                currentSpareData: []


            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
            }
            this.repairOrderMultiAuthQueryLimit();
            this.repairTypeQuery();
            this.repairUrgenLevelQuery();
            this.repairOrderExecLevelQuery();
            this.faultTypeQuery();
            this.faultLevelQuery();
            this.repairOrderExecGradeQuery();

        },
        methods: {
             // 备件类型下拉选
            async sparePartTypeQuery() {
                var param = {
                    userID: this.userID
                }
                const res = await sparePartTypeQuery(param);
                this.sparePartModelArray = res.data;
            },
             onQueryLimit() {
                this.currentPage = 1;
                this.sparePartStockQueryLimit();
                //this.confirmChoices();
            },
            // 紧急程度
            async repairUrgenLevelQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairUrgenLevelQuery(param);
                this.UrgenLevel = selectRes.data;
            },

            // 维修等级
            async repairOrderExecLevelQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairOrderExecLevelQuery(param);
                this.execLevel = selectRes.data
            },
            // 维修类型
            async repairTypeQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairTypeQuery(param);
                this.repairCategory = selectRes.data
            },
            //故障类型下拉框
            async faultTypeQuery() {
                var param = {
                    userID: this.userID
                }
                const selectRes = await faultTypeQuery(param)
                this.typeData = selectRes.data

            },
            //故障等级下拉框
            async faultLevelQuery() {
                var param = {
                    userID: this.userID
                }
                const selectRes = await faultLevelQuery(param)
                this.levelData = selectRes.data
            },
            // 评分等级
            async repairOrderExecGradeQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairOrderExecGradeQuery(param);
                this.gradingArr = selectRes.data
            },


            async repairOrderMultiAuthQueryLimit() {
                this.ID = window.localStorage.getItem('changeOrderID')
                var param = {
                    ID: this.ID,
                    userID: this.userID
                }
                const selectRes = await repairOrderMultiAuthQueryLimit(param);
                this.formInline = selectRes.array[0],
                    this.repairOrderSparePartQuery();
            },
            // 备件
            async repairOrderSparePartQuery() {
                var param = {
                    orderID: this.formInline.orderID
                }
                const selectRes = await repairOrderSparePartQuery(param);
                this.sparePartList = selectRes.rows;
            },
            async repairOrderUpdate() {
                this.ID = window.localStorage.getItem('changeOrderID')

                this.formInline.ID = parseInt(this.ID);
                this.formInline.userID = this.userID;
                this.pList = JSON.parse(this.formInline.pasueTimeList)
                this.formInline.pasueTimeList = this.PList
                this.formInline.urgenLevel = this.formInline.urgenLevel_name
                this.formInline.SparePartJSON = {
                    array: this.tableData
                }
                var param = this.formInline
                const selectRes = await repairOrderUpdate(param);
                this.changeResult = selectRes.data.result
                if (this.changeResult == true) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning'
                    })
                }
            },
            delTable(id) {
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log(id)
                    // this.tableData.splice(row,1)
                    // console.log(this.tableData)
                    this.repairOrderSparePartDelete(id);
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            async repairOrderSparePartDelete(id) {
                var param = {
                    ID: id
                };
                const selectRes = await repairOrderSparePartDelete(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.repairOrderSparePartQuery();
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            editTable(row) {
                this.formSpare = {};
                this.dialogTableVisibleEditSpare = true;
                this.formSpare = row;
            },
            async confirmChoicesEdit() {
                // 
                const selectRes = await repairOrderSparePartUpdate(this.formSpare);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogTableVisibleEditSpare = false;
                    this.formSpare = {};
                    this.repairOrderSparePartQuery();
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            // 修改备件
            changeInputEdit(val) {
                this.formSpare.totalPrice = this.formSpare.unitPrice * this.formSpare.applyQuan
            },
            // 备件--开始
            // 选择备件
            selectSpare() {
                this.formSpare = {};
                this.sparePartStockQueryLimit();
                this.dialogTableVisibleSpare = true;
            },
            // 备件台账-筛选分页数据
            async sparePartStockQueryLimit() {
                var param = {
                    userID: this.userID,
                    pageNumber: this.currentPageSpare,
                    pageSize: this.pageSize,
                    equipmentID: this.formInline.deviceID
                };
                if (this.sparePartModel != '') {
                    param.sparePartModel = this.sparePartModel
                }
                if (this.sparePartName != '') {
                    param.sparePartName = this.sparePartName
                }
                const selectRes = await sparePartStockQueryLimit(param);
                // this.spareList = selectRes.rows;
                this.formData.tableData = selectRes.rows;
                this.pageQuantitySapre = selectRes.pageQuantity
            },
            handleCurrentChangeSpare() {
                this.sparePartStockQueryLimit()
            },
            handleSelectionChange(val) {
                // console.log(val);
                this.currentSpareData = val;
            },
            changeInput(index) {
                // console.log(this.formData)
                // console.log(this.formData.tableData[index])
                this.formData.tableData[index].totalPrice = this.formData.tableData[index].referPrice * this.formData
                    .tableData[index].applyQuan;
            },
            async confirmChoices() {
                // console.log(this.currentSpareData);
                var currentData = [];
                var price = 0;
                if (this.currentSpareData.length == 0) {
                    this.$message({
                        message: '警告! 请选择备件',
                        type: 'warning'
                    });
                    return
                }
                for (var i = 0; i < this.currentSpareData.length; i++) {
                    if (!this.currentSpareData[i].applyQuan) {
                        this.$message({
                            message: '警告哦，“' + this.currentSpareData[i].sparePartName + '”请填写领用数量',
                            type: 'warning'
                        });
                        return
                    }
                    var testData = this.sparePartList.filter(itmer => itmer.sparePartID == this.currentSpareData[i]
                        .sparePartID);
                    console.log(testData)
                    if (testData.length != 0) {
                        var sparesName = []
                        for (var j = 0; j < testData.length; j++) {
                            sparesName.push(testData[i].sparePartName)
                        }
                        this.$message({
                            message: '警告!“' + sparesName.join('、') + '”已添加',
                            type: 'warning'
                        });
                        return
                    }
                    var current = {
                        sparePartID: this.currentSpareData[i].sparePartID,
                        sparePartName: this.currentSpareData[i].sparePartName,
                        sparePartModel: this.currentSpareData[i].sparePartModel,
                        unit: this.currentSpareData[i].unit,
                        unitPrice: this.currentSpareData[i].referPrice,
                        applyQuan: this.currentSpareData[i].applyQuan,
                        totalPrice: this.currentSpareData[i].totalPrice,
                        remarks: this.currentSpareData[i].Remarks,
                    }
                    currentData.push(current);
                    price += Number(this.currentSpareData[i].totalPrice)
                }
                var param = {
                    userName: this.userName,
                    orderID: this.formInline.orderID,
                    SparePartJSON: {
                        array: currentData
                    },
                    totalPrice: price
                }
                console.log(param)
                const selectRes = await repairOrderSparePartInsert(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogTableVisibleSpare = false;
                    this.sparesName = [];
                    this.formData = {
                        tableData: []
                    }
                    this.repairOrderSparePartQuery();
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            // 选择备件--结束

            submit() {
                this.repairOrderUpdate()
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            }
        }

    }
</script>
<style scoped>
    /* .details_box {
        width: 70%;
    }

    .details_box .el-form {
        width: 100%;
    } */

    /* .textarea .el-form-item__content {
        width: 84%
    } */
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }

    ::v-deep .el-form-item__label {
        line-height: 24px;
    }
</style>